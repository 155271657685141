import { Stack, Text } from '@mantine/core'

import { Organization as OrganizationType } from '@/types'

type Props = OrganizationType & {
  typographyProps?: any
}

export default function Organization({
  officialName,
  fantasyName,
  documents,
  typographyProps
}: Props) {
  return (
    <Stack spacing={0}>
      <Text hidden={!officialName} {...typographyProps}>{officialName}</Text>
      <Text hidden={!fantasyName} {...typographyProps}>{fantasyName}</Text>
      {Array.isArray(documents) &&
        documents.map(document => (
          <Text key={document?.document} {...typographyProps}>
            {document?.name && `${document?.name}: `}
            {document?.document || ''}
          </Text>
        ))}
    </Stack>
  )
}
