interface Props {
  paymentMethodCode: string
}

export default function PaymentMethodTitle({ paymentMethodCode }: Props) {
  switch (paymentMethodCode) {
    case 'pix':
      return <>Pix</>
    case 'bankDebit':
      return <>Débito em conta</>
    case 'billingNote':
      return <>Boleto bancário</>
    case 'installmentBillingNote':
      return <>Boleto parcelado</>
    case 'creditCard':
      return <>Cartão de crédito</>
    default:
      return <></>
  }
}
