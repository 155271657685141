import { FormattedNumber } from 'react-intl'

interface Props {
  value: number
  free?: boolean
  zeroText?: string
}

export default function CurrencyField({ value, free, zeroText, ...otherProps }: Props) {
  const number = Number(value)

  if (!Number.isNaN(number)) {
    if ((zeroText !== undefined || free) && number === 0) {
      return <>{zeroText || 'Grátis'}</>
    }
    // eslint-disable-next-line react/style-prop-object
    return <FormattedNumber value={value} style="currency" currency="BRL" {...otherProps} />
  }

  // eslint-disable-next-line react/jsx-fragments
  return <>{value}</>
}
