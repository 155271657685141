import { Stack, Text } from '@mantine/core'

interface Props {
  holder: string
  agencyNumber: string
  agencyCheckNumber: string
  accountNumber: string
  accountCheckNumber: string
  taxDocument: string
  bankName: string
  bankCode: string
}

export default function BankAccount({
  holder,
  agencyNumber,
  agencyCheckNumber,
  accountNumber,
  accountCheckNumber,
  taxDocument,
  bankName,
  bankCode
}: Props) {
  return (
    <Stack spacing={0}>
      <Text>
        Banco (Cód. {bankCode}) {bankName}
      </Text>
      {agencyNumber && (
        <Text size="sm" color="dimmed">
          Agência: {agencyNumber}-{agencyCheckNumber}
        </Text>
      )}
      {accountNumber && (
        <Text size="sm" color="dimmed">
          Conta Corrente: {accountNumber}-{accountCheckNumber}
        </Text>
      )}
      {holder && (
        <Text size="sm" color="dimmed">
          {holder}
        </Text>
      )}
      {taxDocument && (
        <Text size="sm" color="dimmed">
          Documento: {taxDocument}
        </Text>
      )}
    </Stack>
  )
}
