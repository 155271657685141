import Head from 'next/head'
import { useRouter } from 'next/router'

import { useFetch } from '@/lib/hooks'
import { SiteData as SiteDataType } from '@/types'

interface Props {
  initialData?: SiteDataType
}

export default function CommonHead({ initialData }: Props) {
  // Hooks
  const router = useRouter()

  // Constants
  const { site: siteSlug } = router?.query || {}

  // Fetch
  const { data: siteData } = useFetch([siteSlug ? `/${siteSlug}/` : null], {
    fallbackData: initialData?.site
  })

  if (!siteSlug) return null

  return (
    <Head>
      <link rel="icon" sizes="32x32" href={siteData?.favicon || siteData?.logo} />
    </Head>
  )
}
