import isNumber from 'lodash/isNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedNumber } from 'react-intl'

interface Props {
  value: number
}

function PercentageField({ value }: Props) {
  const number = Number(value)

  if (!Number.isNaN(number) && isNumber(number)) {
    return <FormattedNumber
      value={value}
      style="percent"
      maximumFractionDigits={2}
    />
  }

  return <>{value}</>
}

PercentageField.propTypes = {
  value: PropTypes.any
}

export default PercentageField
