import React from 'react'

import CommonHead from './components/CommonHead'
import CustomScripts from './components/CustomScripts'

interface Props {
  Layout?: React.FC<object>
  layoutProps?: object
  View?: React.FC<object>
  viewProps?: object
  Guard?: React.FC<object>
  initialData?: object
  children?: React.ReactNode
}

export default function AssembleLayout({
  Layout = React.Fragment,
  layoutProps = {},
  View = React.Fragment,
  viewProps = {},
  Guard = React.Fragment,
  initialData = {},
  children
}: Props) {
  return (
    <Layout {...(Layout && Layout !== React.Fragment && { ...layoutProps, ...initialData })}>
      <CommonHead { ...initialData } />
      {children}
      <Guard {...(Guard && Guard !== React.Fragment && initialData)}>
        <View {...(View && View !== React.Fragment && { ...viewProps, ...initialData })} />
      </Guard>
      <CustomScripts { ...initialData } />
    </Layout>
  )
}
