import { SiteData as SiteDataType } from '@/types'

interface Props {
  initialData?: SiteDataType
}

export default function CustomScripts({ initialData }: Props) {
  if (!initialData?.site) return null

  return (
    <>
      {initialData?.site?.customScripts && (
        <div dangerouslySetInnerHTML={{ __html: initialData.site.customScripts }} />
      )}
    </>
  )
}
