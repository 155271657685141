import { Box, Group, Text, Title, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import { Address as AddressType } from '@/types'

type Props = {
  address: AddressType
  hidden?: boolean
}

export default function AddressText({ address, hidden = false }: Props) {
  // Hooks
  const theme = useMantineTheme()
  const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`)

  return (
    <Box hidden={hidden}>
      <Title order={isXs ? 6 : 4}>{address?.label}</Title>
      <Text weight="bold">
        {address?.line1}, {address?.line4} - {address?.line2}
      </Text>
      <Group spacing={0}>
        <Text size="sm" color="dimmed" italic hidden={!address?.recipientName}>
          {address?.recipientName}
        </Text>
        <Text
          size="sm"
          color="dimmed"
          italic
          hidden={!address?.recipientName || !address?.phoneNumber}
          mx="xs">
          -
        </Text>
        <Text size="sm" color="dimmed" italic hidden={!address?.phoneNumber}>
          {address?.phoneNumber}
        </Text>
      </Group>
      <Text italic hidden={!address?.line3}>
        {address?.line3}
      </Text>
      <Text>
        {address?.city} / {address?.stateCode}
        <Text component="span"> | CEP: {address?.postcode}</Text>
      </Text>
    </Box>
  )
}
