import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { FormattedDate, FormattedTime } from 'react-intl'

interface Props {
  value: any
  emptyDisplay?: any
  type?: 'date' | 'time' | 'datetime' | 'relative'
  dateProps?: object
  timeProps?: object
}

function DateField({ value, type = 'date', emptyDisplay = '-', dateProps = {}, timeProps = {} }: Props) {
  if (value && value.includes('-')) {
    const isSP = typeof value === 'string' && value.includes('-03:00')
    const timeZone = isSP ? 'America/Sao_Paulo' : 'UTC'
    if (type === 'datetime') {
      return (
        <>
          <FormattedDate value={value} timeZone={timeZone} {...dateProps} />{' '}
          <FormattedTime value={value} timeZone={timeZone} {...timeProps} />
        </>
      )
    }

    if (type === 'time') return <FormattedTime value={value} timeZone={timeZone} />

    if (type === 'relative') {
      dayjs.extend(relativeTime)
      return <>{dayjs(value).fromNow()}</>
    }

    return <FormattedDate value={value} timeZone={timeZone} {...dateProps} />
  }

  return <>{value || emptyDisplay}</>
}

export default DateField
