import { Stack, Text } from '@mantine/core'

import { Customer as CustomerType } from '@/types'

type Props = CustomerType & {
  typographyProps?: any
}

export default function Customer({
  email,
  phoneCountryCode,
  phoneAreaCode,
  phoneNumber,
  firstName,
  lastName,
  document,
  documentType,
  picture,
  typographyProps
}: Props) {
  return (
    <Stack spacing={0}>
      <Text {...typographyProps}>{`${firstName || ''}${firstName && lastName && ' '}${
        lastName || ''
      }`}</Text>
      <Text {...typographyProps}>{email}</Text>
      <Text {...typographyProps}>
        {!!documentType && `${documentType}:`} {document}
      </Text>
      <Text {...typographyProps}>
        {!!phoneCountryCode && `+${phoneCountryCode}`} {!!phoneAreaCode && `(${phoneAreaCode})`}{' '}
        {phoneNumber}
      </Text>
    </Stack>
  )
}
