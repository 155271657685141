import { Modal, Text, UnstyledButton } from '@mantine/core'
import { isArray, isArrayLikeObject, isDate, isObject, isString } from 'lodash'
import React, { useState } from 'react'

import { DateField } from '.'

type ValueObjectProps = { name: string, value: string, quantity: number }

interface Props {
  value: Date | string | string[] | ValueObjectProps | ValueObjectProps[]
  type: string
}

export default function ComplementValue({ value, type }: Props) {
  const color = 'dimmed'
  const size = 'xs'

  const [open, setOpen] = useState(false)

  switch (type) {
    case 'integer':
    case 'float':
    case 'text':
      return isString(value) ? <Text color={color} size={size}>{value}</Text> : <></>
    case 'richtext':
      return isString(value) ? <>
        <UnstyledButton onClick={() => setOpen(true)}>
          <Text color="blue.5" size={size} weight="bold">Pré-visualizar</Text>
        </UnstyledButton>
        <Modal
          opened={open}
          onClose={() => setOpen(false)}
          title={<Text weight="bold">Pré-visualização</Text>}
          centered>
          <div dangerouslySetInnerHTML={{ __html: value }}></div>
        </Modal>
      </> : <></>
    case 'boolean':
      return <Text color={color} size={size}>{value ? 'sim' : 'não'}</Text>
    case 'date':
    case 'datetime':
      return isDate(value) || isString(value) ? <Text color={color} size={size}>
        <DateField value={value} type={type} />
      </Text> : <></>
    case 'option':
      return isObject(value) &&
        !isArray(value) &&
        !isDate(value)
        ? <Text color={color} size={size}>{value.name}</Text> : <></>
    case 'multioption':
      return isArrayLikeObject(value)
        ? <>{value.map(({ name, value }: any) =>
            <Text key={value} color={color} size={size}>- {name}</Text>
          )}</>
        : <></>
  }
  return <>{value}</>
}
